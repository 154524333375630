import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SEO from "components/seo";
import Social from "components/TranslatorHub/Social";
import { graphql, Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import moment from "moment";
import React, { useState } from "react";
import TranslatorHubHeader from "../TranslatorHub/TranslatorHubHeader/TranslatorHubHeader";
import Form from "./Form";
import loadable from "@loadable/component";
import Pagination from "components/TranslatorHub/pagination";

export const businessCenterQuery = graphql`
  query businessCenterQuery($limit: Int!, $skip: Int!) {
    allBusinessCenter(
      sort: { fields: [data___seo_project_create_date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          data {
            cover_image
            language
            slug
            seo_h1
            category
            category_name
            alt_tag
            seo_project_create_date
            seo_project_small_desc
            seo_project_id
            read_time
          }
        }
      }
    }
  }
`;

const BusinessCenterPage = (props) => {
  const Footer = loadable(() => import("components/layout/footer"));
  const FormMobile = loadable(() => import("./Form"));


  const title = "Business Translation Center | Tomedes Translation";
  const description =
    "Tomedes Business Translation Zone | Useful and relevant information for Business clients, startups and high tech companies | Everything you need to know about professional translation and translation for businesses.";
  // const [category, setCatogery] = useState(null);
  const businessCenterData = props.data.allBusinessCenter.edges;

  // const handleLinkQuery = (e) => {
  //   setCatogery(e);
  // };
  var canonical = "/business-center.php";
  if(props.pageContext.currentPage !== 1){
    canonical = "/business-center.php/p/"+props.pageContext.currentPage;
  }


  return (
    <React.Fragment>
      <TranslatorHubHeader title={"Business Translation Center"} />
      <SEO title={title} description={description} slug={canonical} />
      <div className="flex items-center justify-center w-11/12 xl:w-full mx-auto">
        <div className="flex justify-center md:flex-row flex-col w-full max-w-screen-2xl mt-2">
          <div className="px-4 xl:px-0 lg:mr-20 xl:w-5/12 mt-5">
            <ul>
            {businessCenterData?.map(({ node },i) => {
              const { data } = node;
              const {
                cover_image,
                alt_tag,
                category_name,
                seo_project_id,
                seo_project_create_date,
                seo_project_small_desc,
                seo_project_publish_date,
                slug,
                read_time,
                seo_h1,
              } = data;
              return (
                <li
                  className="w-full border-newBorder border-b-3 mb-7"
                  key={seo_project_id}
                >
                  <Link to={slug}>
                    <span className="text-2xl py-4 text-newGray hover:text-newLightBlue leading-none">
                      {seo_h1}
                    </span>
                  </Link>
                  <Link to={slug}>
                    <OptimizedImage
                      src={cover_image}
                      alt={alt_tag}
                      className="w-full object-cover h-auto lg:h-[370px] mt-5"
                      lazyload={i < 1 ? false : true}
                    />
                  </Link>
                  <p className="pb-2 pt-3 text-17  font-normal font-serif">
                    CATEGORY /
                    <span className="text-lightBlue mt-1 mb-2">
                      {category_name}
                    </span>
                  </p>
                  <i className="pb-5 inline-block font-light font-serif">
                  {seo_project_publish_date ? moment(seo_project_publish_date).format("DD/MM/YYYY"):moment(seo_project_create_date).format("DD/MM/YYYY")}
                   </i>
                  <p className="pb-5 font-opensans font-normal italic ">
                    {seo_project_small_desc}
                  </p>
                  <Link to={slug}>
                    <i className="inline-flex mt-4 items-center pb-5 font-light font-serif text-newGray">
                      Read More
                      <FontAwesomeIcon icon={faAngleRight} className="ml-6" />
                    </i>
                  </Link>
                  <span className="block pb-5 font-light font-serif text-sm">
                    {read_time}
                  </span>
                  <span className=""></span>
                </li>
              );
            })}
            </ul>
            <Pagination
              totalPages={props.pageContext.totalPages}
              currentPage={props.pageContext.currentPage}
              baseUrl="/business-center.php"
            />
          </div>
          <div className=" md:ml-12 ml-0 mt-10 md:mt-0 px-4 lg:pl-0 lg:pr-2">
            <div>
              <h5 className="font-bold text-newGray text-xl mt-4">
                CATEGORIES
              </h5>
              <ul className="text-newGray">
                {props.pageContext.uniqueCategories.map((link, index) => (
                  <li
                    className="text-new py-2  hover:text-newLightBlue cursor-pointer"
                    key={index}
                  >
                    <Link to={`/business-center.php/category/${link}`}>{link}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="line border-b border-gray mt-12"></div>
            <div className="hidden md:block">
              <Form />
            </div>
            <div className="md:hidden">
              <FormMobile/>
            </div>
            <Social />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default BusinessCenterPage;